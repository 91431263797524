<template>
    <div class="p1Div">
        <el-row>
            <el-col :span="24">
                <MenuPath></MenuPath>
            </el-col>
        </el-row>
        <div class="containerDiv">
            <el-row>
                <el-col :span="3" class="selectTitle">
                    <span>公司:</span>
                </el-col>
                <el-col :span="4" class="selectInpud">
                    <el-select v-model="companyId" placeholder=""
                               @change="handleCompanyChange">
                        <el-option :key="'placeholder'" :label="'請選擇'" :value="null"></el-option>
                        <el-option
                                v-for="item in companyOptions"
                                :key="item.companyId"
                                :label="item.companyName"
                                :value="item.companyId"
                                :disabled="item.disabled">
                        </el-option>
                    </el-select>
                </el-col>
                <el-col :span="3" class="selectTitle">
                    <span>分店:</span>
                </el-col>
                <el-col :span="4" class="selectInpud">
                    <el-select v-model="branchId" placeholder="">
                        <el-option :key="'placeholder'" :label="'請選擇'" :value="null"></el-option>
                        <el-option
                                v-for="item in branchOptions"
                                :key="item.branchId"
                                :label="item.branchName"
                                :value="item.branchId"
                                :disabled="item.disabled">
                        </el-option>
                    </el-select>
                </el-col>
<!--                <el-col :span="3" class="selectTitle">-->
<!--                    <span>暱稱:</span>-->
<!--                </el-col>-->
<!--                <el-col :span="4" class="selectInpud">-->
<!--                    <el-input v-model="nickName" placeholder="請輸入暱稱"></el-input>-->
<!--                </el-col>-->
            </el-row>
            <el-row>
                <el-col :span="3" class="selectTitle">
                    <span>帳號/暱稱:</span>
                </el-col>
                <el-col :span="4" class="selectInpud">
                    <el-input v-model="userName" placeholder="請輸入帳號/暱稱"></el-input>
                </el-col>
                <el-col :span="3" class="selectTitle">
                    <span>是否啟用:</span>
                </el-col>
                <el-col :span="4" class="selectInpud">
                    <el-radio-group v-model="isActive">
                        <el-radio label="0">全部</el-radio>
                        <el-radio label="1">啟用</el-radio>
                        <el-radio label="2">不啟用</el-radio>
                    </el-radio-group>
                </el-col>
            </el-row>
            <el-row>
                <el-col :span="3" class="selectBatton">
                    <div class="grid-content bg-purple-light">
                        <el-button @click="findEntityList()" type="primary" size="medium">查詢
                        </el-button>
                    </div>
                </el-col>
                <el-col :span="3" class="selectBatton">
                    <div class="grid-content bg-purple-light">
                        <el-button @click="clearData()" type="primary" size="medium">清除
                        </el-button>
                    </div>
                </el-col>
            </el-row>
        </div>
        <el-row>
            <el-col :span="8" class="addBatton">
                <div class="grid-content bg-purple-light">
                    <el-button v-if="addButton" @click="add()" type="primary" size="medium">新增員工
                    </el-button>
                </div>
            </el-col>
        </el-row>
        <el-row>
            <el-col :span="24">
                <TableTemplate ref="tableTemplate" :tableData="tableData" :columns="columns" :total="total"
                               :loading="loading" :defaultsort="defaultsort" :currentPage="currentPage"
                               :pageSize="pageSize"
                               :direction="direction" :orderByName="orderByName" :checkBoxShow="checkBoxShow"
                               :inDetailedButton="inDetailedButton"
                               :inEditButton="inEditButton"
                               :inDeleteButton="inDeleteButton"
                               @getTableDataList="findEntityList" @editClick="editClick" @deleteClick="deleteClick"
                               @detailedClick="detailedClick"
                ></TableTemplate>
            </el-col>
        </el-row>
        <EditDialog :editDialog="editDialog" @closeDialog="closeDialog" @init="init" ref="editDialog"
                    @update:reload="handleReloadUpdate"></EditDialog>
        <AddDialog :addDialog="addDialog" @closeDialog="closeDialog" @init="init" ref="addDialog"
                   @update:reload="handleReloadUpdate"></AddDialog>
        <DetailedDialog :detailedDialog="detailedDialog" @closeDialog="closeDialog" @init="init"
                        ref="detailedDialog" @update:reload="handleReloadUpdate"></DetailedDialog>
    </div>

</template>

<script>
    import TableTemplate from '@/components/element/TableTemplate.vue'
    import MenuPath from '@/components/element/MenuPath.vue'
    import tableColumns from '@/assets/js/staffHomeTable.js'
    import EditDialog from '@/views/staff/EditStaffDialog.vue'
    import AddDialog from '@/views/staff/AddStaffDialog.vue'
    import DetailedDialog from '@/views/staff/DetailedStaffDialog.vue'

    export default {
        name: "staffHome",
        components: {
            MenuPath,
            TableTemplate,
            EditDialog,
            AddDialog,
            DetailedDialog,
        },
        data() {
            return {
                //表頭設定
                columns: [],
                //table數據
                tableData: [],
                //總筆數
                total: 0,
                //是否啟用載入動畫效果
                loading: false,
                //預設排序
                defaultsort: {prop: 'userName', order: 'ascending'},
                // 当前页
                currentPage: 1,
                //每页多少条
                pageSize: 10,
                //排序:ASC,DESC
                direction: 'ASC',
                //依據屬性欄位排序
                orderByName: 'userName',
                checkBoxShow: false,
                editDialog: false,
                addDialog: false,
                detailedDialog: false,
                token: null,
                companyId: null,
                inCompanyId: null,
                role: null,
                nickName: null,
                userName: null,
                loginReturnDto: null,
                isActive: "1",
                addButton: null,
                //按鈕
                inDetailedButton: null,
                inEditButton: null,
                inDeleteButton: null,
                branchId: null,
                reload: false,
                companyOptions: [],
                branchOptions: [],
                permissionControlList: null,
                page: "STAFF",
                functionCodes: ['ADD', 'QRY', 'EDIT', 'DEL', 'VIEW'],
                //是否需要公司ID
                isCompanyid: true,
                //是否需要分店ID
                isBranchid: true,
            }
        },
        mounted() {
            this.columns = tableColumns;
            this.init();
        },
        methods: {
            init() {
                // 從 Local Storage 獲取 loginReturnDto
                this.isCompanyid = JSON.parse(localStorage.getItem('isCompanyid'));
                this.isBranchid = JSON.parse(localStorage.getItem('isBranchid'));
                console.log("staffHome取得isCompanyid=" + this.isCompanyid);
                console.log("staffHome取得isBranchid=" + this.isBranchid);
                this.loginReturnDto = localStorage.getItem('loginReturnDto');
                if (this.loginReturnDto != null && JSON.parse(this.loginReturnDto) != null) {
                    this.loginReturnDto = JSON.parse(this.loginReturnDto);
                    this.token = this.loginReturnDto.token;
                    this.role = this.loginReturnDto.role;
                    if (this.isCompanyid) {
                        this.companyId = this.loginReturnDto.companyId;
                        this.inCompanyId = this.loginReturnDto.companyId;
                        console.log("staffHome取得loginReturnDto.companyId=" + this.companyId);
                    }
                    if (this.isBranchid) {
                        this.branchId = this.loginReturnDto.branchId;
                        console.log("staffHome取得loginReturnDto.branchId=" + this.branchId);
                    }
                    //console.log("staffHome取得token=" + this.token);
                } else {
                    console.log("staffHome未取得token資料，回到登入頁面!");
                    this.$api.openMsg("尚未登入完成，請重新登入!", "error");
                    this.$router.push({path: '/login'});
                    return;
                }

                //權限判斷
                this.checkPermission();
                this.searchCompanyList();
                this.searchBranchList();
                console.log("staffHome取得addButton=" + this.addButton);
                this.currentPage = 1;
                this.pageSize = 10;
                this.findEntityList(this.currentPage, this.pageSize, this.orderByName, this.direction);
            },
            checkPermission() {
                //權限判斷
                const permissionControl = localStorage.getItem('permissionControl');
                if (permissionControl != null && JSON.parse(permissionControl) != null) {
                    this.permissionControlList = JSON.parse(permissionControl);
                    console.log("companyHome取得permissionControlList=" + this.permissionControlList.length);
                }
                if (this.permissionControlList != null && this.permissionControlList.length > 0) {
                    const results = this.$api.findAndCategorizePermissions(this.permissionControlList, this.page, this.functionCodes);
                    this.addButton = this.$api.hasDataForSpecificFunctionCode(results, "ADD") ? true : false;
                    this.qryButton = this.$api.hasDataForSpecificFunctionCode(results, "QRY") ? true : false;
                    this.inEditButton = this.$api.hasDataForSpecificFunctionCode(results, "EDIT") ? true : false;
                    this.inDeleteButton = this.$api.hasDataForSpecificFunctionCode(results, "DEL") ? true : false;
                    this.inDetailedButton = this.$api.hasDataForSpecificFunctionCode(results, "VIEW") ? true : false;
                }
                console.log("companyHome取得addButton=" + this.addButton);
                console.log("companyHome取得qryButton=" + this.qryButton);
                console.log("companyHome取得inEditButton=" + this.inEditButton);
                console.log("companyHome取得inDeleteButton=" + this.inDeleteButton);
                console.log("companyHome取得inDetailedButton=" + this.inDetailedButton);
                if (!this.inEditButton && !this.inDetailedButton && !this.inDeleteButton) {
                    //按鈕都隱藏時，不顯示操作欄位
                    this.columns = this.columns.filter(column => column.prop !== 'action');
                }
            },
            async findEntityList(currentPage, pageSize, orderByName, direction) {
                /**
                 * vue axios 同步請求
                 * **/
                console.log("findEntityList in .....");
                console.log("currentPage=" + currentPage);
                console.log("pageSize=" + pageSize);
                console.log("orderByName=" + orderByName);
                console.log("direction=" + direction);
                if (!this.qryButton) {
                    console.log("登入者無查詢權限!");
                    this.$api.openMsg("登入者無查詢權限!", "error");
                    return;
                }
                this.currentPage = currentPage;
                this.pageSize = pageSize;
                this.orderByName = orderByName;
                this.direction = direction;
                this.loading = true;
                let tmp = this;
                let url = "staff/search/staff";
                let parameter = {
                    "companyId": tmp.companyId,
                    "branchId": tmp.branchId,
                    "nickName": tmp.nickName,
                    "userName": tmp.userName,
                    "isActive": this.isActive == null || this.isActive == "0" ? null : this.isActive == "1" ? true : false,
                    "pageNo": tmp.currentPage,
                    "dataSize": tmp.pageSize,
                    "orderByName": tmp.orderByName,
                    "direction": tmp.direction
                };
                console.log("companyHome取得isActive=" + this.isActive);
                //取得列表資料
                let responseData = await tmp.$api.doPostByToken(parameter, url, this.token);
                if (responseData != null) {
                    tmp.tableData = responseData;
                    if (tmp.tableData != null && tmp.tableData.length > 0) {
                        tmp.total = tmp.tableData[0].totalRecords;
                        console.log("total=" + tmp.total);
                    } else {
                        tmp.total = 0;
                        console.log("total=" + tmp.total);
                    }
                }
                this.loading = false;
                console.log("asyncTest----end");
            },
            clearData() {
                //清除查詢資料
                this.nickName = null;
                this.userName = null;
                this.isActive = '1';
                if (this.isCompanyid) {
                    this.companyId = this.loginReturnDto.companyId;
                    this.inCompanyId = this.loginReturnDto.companyId;
                    console.log("customerHome取得loginReturnDto.companyId=" + this.companyId);
                } else {
                    this.companyId = null;
                    this.inCompanyId = null;
                }
                if (this.isBranchid) {
                    this.branchId = this.loginReturnDto.branchId;
                    console.log("customerHome取得loginReturnDto.branchId=" + this.branchId);
                } else {
                    this.branchId = null;
                }
            },
            closeDialog() {
                this.editDialog = false;
                this.addDialog = false;
                this.detailedDialog = false;
                console.log("reload=" + this.reload);
                //判斷是否需要重載畫面
                if (this.reload) {
                    window.location.reload();
                }
            },
            editClick(row) {
                console.log("編輯 row=" + row);
                if (!this.inEditButton) {
                    this.$api.openMsg("未具有權限!", "error");
                    return;
                }
                //編輯
                this.$refs.editDialog.init(row);
                this.editDialog = true;
            },
            detailedClick(row) {
                //詳細頁
                console.log("詳細頁 row=" + row);
                this.$refs.detailedDialog.init(row);
                this.detailedDialog = true;
            },
            async deleteClick(row) {
                //刪除
                console.log("刪除 row=" + row);
                let tmp = this;
                let status = false;
                await this.$confirm('是否確定刪除該筆資料?', '提示', {
                    confirmButtonText: '確定',
                    cancelButtonText: '取消',
                    type: 'warning'
                }).then(() => {
                    status = true;
                }).catch(() => {
                    status = false;
                    return;
                });
                if (status) {
                    let url = "staff/deleteStaff/" + row.staffId;
                    let responseData = await tmp.$api.doDeleteByToken(url, this.token);
                    if (responseData) {
                        tmp.$api.openMsg("刪除資料成功!", "success");
                        // 在延迟 2000 毫秒（2 秒）后执行代码
                        setTimeout(function () {
                            tmp.init();
                            console.log("刪除資料成功!");
                        }, 1000);
                    } else {
                        tmp.$api.openMsg("刪除資料失敗!", "error");
                        console.log("刪除資料失敗!");
                    }
                }
            },
            add() {
                console.log("新增...role=" + this.role);
                if (!this.addButton) {
                    this.$api.openMsg("未具有權限!", "error");
                    return;
                }
                this.$refs.addDialog.init();
                this.addDialog = true;
            },
            handleReloadUpdate(newValue) {
                this.reload = newValue;
            },
            async searchCompanyList() {
                //查詢全部公司下拉選單資料
                let tmp = this;
                let url = "login/search/searchCompanyOptions";
                let parameter = {
                    "companyId": this.inCompanyId,
                    "companyName": "",
                    "isActive": true
                };
                //取得列表資料
                let responseData = await tmp.$api.doPost(parameter, url);
                if (responseData != null) {
                    tmp.companyOptions = responseData;
                    console.log("取得下拉選單資料 companyOptions=" + tmp.companyOptions);
                }
            },
            async searchBranchList() {
                //查詢全部分店下拉選單資料
                let tmp = this;
                let url = "login/search/searchBranchOptions";
                let parameter = {
                    "companyId": this.companyId,
                    "branchId": this.branchId,
                    "branchName": "",
                    "isActive": true
                };
                //取得列表資料
                let responseData = await tmp.$api.doPostByToken(parameter, url, this.token);
                if (responseData != null) {
                    tmp.branchOptions = responseData;
                    console.log("取得下拉選單資料 companyOptions=" + tmp.companyOptions);
                }
            },
            handleCompanyChange(value) {
                console.log("Selected company ID:", value);
                console.log("staffHome取得isCompanyid=" + this.isCompanyid);
                //系統管理員權限時會查詢一次該公司所屬的分店
                if (!this.isCompanyid) {
                    this.inCompanyId = value;
                    this.branchId = null;
                    this.searchBranchList();
                }
            }
        }
    }
</script>

<style scoped>
    .p1Div {
        width: 99%;
    }

</style>
