<template>
    <div class="popDialog">
        <el-dialog
                v-if="detailedDialog"
                width="95%"
                top="30px"
                :visible.sync="detailedDialog"
                :before-close="closeDialog"
                :fullscreen="dialogFull">
            <template v-slot:title>
                <el-row>
                    <el-col :span="4" class="selectTitleMax">
                        <span>員工詳細資料</span>
                    </el-col>
                </el-row>
            </template>
            <el-row>
                <el-col :span="24">
                    <div class="containerDiv">
                        <el-row>
                            <el-col :span="3" class="detailedTitle">
                                <span>姓名:</span>
                            </el-col>
                            <el-col :span="4" class="detailed">
                                <span>{{row.name}}{{row.lastName}}</span>
                            </el-col>
                            <el-col :span="3" class="detailedTitle">
                                <span>暱稱:</span>
                            </el-col>
                            <el-col :span="4" class="detailed">
                                <span>{{row.nickName}}</span>
                            </el-col>
                            <el-col :span="3" class="detailedTitle">
                                <span>性別:</span>
                            </el-col>
                            <el-col :span="4" class="detailed">
                                <span>{{row.genderString}}</span>
                            </el-col>
                        </el-row>
                    </div>
                </el-col>
            </el-row>
        </el-dialog>
    </div>
</template>

<script>

    export default {
        name: "detailedDialog",
        components: {},
        props: {
            detailedDialog: null,
        },
        data() {
            return {
                dialogFull: false,
                row: null,
                token: null,
                companyId: null,
                reload: false,
            }
        },
        mounted() {
        },
        methods: {
            init(row) {
                this.row = row.user;
                const loginReturnDto = localStorage.getItem('loginReturnDto');
                if (loginReturnDto != null && JSON.parse(loginReturnDto) != null) {
                    this.loginReturnDto = JSON.parse(loginReturnDto);
                    this.token = this.loginReturnDto.token;
                    this.companyId = this.loginReturnDto.companyId;
                    //console.log("editCompanyDialog取得token=" + this.token);
                }
            },
            loadingView() {
                //開啟載入特效
                this.loadingStatus = this.$loading({
                    lock: true,
                    text: 'Loading',
                    spinner: 'el-icon-loading',
                    background: 'rgba(0, 0, 0, 0.7)'
                });
            },
            closeDialog() {
                console.log("關閉Pop");
                this.$emit('update:reload', this.reload);
                this.$emit('closeDialog');
            },
            closeLoading() {
                //關閉載入特效
                if (this.loadingStatus != null) {
                    this.loadingStatus.close();
                }
            },
        }
    }
</script>

<style scoped>

    .detailedTitle {
        text-align: left;
        font-size: 20px;
        font-weight: bold;
        margin: 15px 0px 15px 15px
    }

    .detailed {
        text-align: left;
        font-size: 20px;
        margin: 15px 0px;
    }

</style>
