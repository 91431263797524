<template>
    <div class="popDialog">
        <el-dialog
                v-if="editDialog"
                width="95%"
                top="30px"
                :visible.sync="editDialog"
                :before-close="closeDialog"
                :fullscreen="dialogFull">
            <template v-slot:title>
                <el-row>
                    <el-col :span="12" class="selectTitleMax">
                        <span>編輯資料</span>
                    </el-col>
                </el-row>
            </template>
            <el-row>
                <el-col :span="24">
                    <div class="containerDiv">
                        <el-row>
                            <el-col :span="4" class="selectTitleMid">
                                <span>帳號資料</span>
                            </el-col>
                        </el-row>
                        <el-row>
                            <el-col :span="3" class="selectTitle">
                                <span>公司<span class="red">*</span>:</span>
                            </el-col>
                            <el-col :span="4" class="selectInpud">
                                <el-select v-model="companyId" placeholder=""
                                           @change="handleCompanyChange">
                                    <el-option :key="'placeholder'" :label="'請選擇'" :value="null"></el-option>
                                    <el-option
                                            v-for="item in companyOptions"
                                            :key="item.companyId"
                                            :label="item.companyName"
                                            :value="item.companyId"
                                            :disabled="item.disabled">
                                    </el-option>
                                </el-select>
                            </el-col>
                            <el-col :span="3" class="selectTitle">
                                <span>分店<span class="red">*</span>:</span>
                            </el-col>
                            <el-col :span="4" class="selectInpud">
                                <el-select v-model="branchId" placeholder="">
                                    <el-option :key="'placeholder'" :label="'請選擇'" :value="null"></el-option>
                                    <el-option
                                            v-for="item in branchOptions"
                                            :key="item.branchId"
                                            :label="item.branchName"
                                            :value="item.branchId"
                                            :disabled="item.disabled">
                                    </el-option>
                                </el-select>
                            </el-col>
                        </el-row>
                        <el-row>
                            <el-col :span="3" class="selectTitle">
                                <span>帳號<span class="red">*</span>:</span>
                            </el-col>
                            <el-col :span="4" class="selectInpud">
                                <el-input
                                        v-model="userName"
                                        placeholder="請輸入帳號"

                                        @input="validateUsername"
                                ></el-input>
                            </el-col>
                            <el-col :span="3" class="selectTitle">
                                <span>密碼<span class="red">*</span>:</span>
                            </el-col>
                            <el-col :span="4" class="selectInpud">
                                <el-input
                                        :type="inputType"
                                        v-model="password"
                                        placeholder="請輸入密碼"
                                        style="width: calc(100% - 30px)"
                                        @input="validatePassword"></el-input>
                                <span
                                        class="eye-icon"
                                        @click="togglePasswordVisibility">
                                    {{ passwordVisible ? '👁️' : ' 👁️‍🗨️' }}</span>
                            </el-col>
                            <el-col :span="3" class="selectTitle">
                                <span>是否啟用<span class="red">*</span>:</span>
                            </el-col>
                            <el-col :span="4" class="selectInpud">
                                <el-radio-group v-model="isActive"
                                >
                                    <el-radio label="1">啟用</el-radio>
                                    <el-radio label="0">不啟用</el-radio>
                                </el-radio-group>
                            </el-col>
                        </el-row>
                        <el-row>
                            <el-col :span="3" class="selectTitle">
                                <span>錯誤次數:</span>
                            </el-col>
                            <el-col :span="4" class="selectInpud">
                                <el-input v-model="errorCount" placeholder="請輸入錯誤次數"
                                          @input="validateErrorCount"></el-input>
                            </el-col>
                            <el-col :span="3" class="selectTitle">
                                <span>角色<span class="red">*</span>:</span>
                            </el-col>
                            <el-col :span="4" class="selectInpud">
                                <el-select v-model="role" placeholder="">
                                    <el-option :key="'placeholder'" :label="'請選擇'" :value="null"></el-option>
                                    <el-option
                                            v-for="item in roleOptions"
                                            :key="item.role"
                                            :label="item.roleName"
                                            :value="item.role"
                                            :disabled="item.disabled">
                                    </el-option>
                                </el-select>
                            </el-col>
                            <el-col :span="3" class="selectTitle">
                                <span>帳號狀態<span class="red">*</span>:</span>
                            </el-col>
                            <el-col :span="4" class="selectInpud">
                                <el-radio-group v-model="state"
                                >
                                    <el-radio label="0">鎖定</el-radio>
                                    <el-radio label="1">使用中</el-radio>
                                </el-radio-group>
                            </el-col>
                        </el-row>
                        <el-row>
                            <el-col :span="3" class="selectTitle">
                                <span>解除時間:</span>
                            </el-col>
                            <el-col :span="4" class="selectInpud">
                                <el-date-picker
                                        v-model="unlockDate"
                                        type="datetime"
                                        placeholder="選擇日期和時間"
                                        @change="dateChangeFormat"
                                >
                                </el-date-picker>
                            </el-col>
                            <el-col :span="3" class="selectTitle">
                                <span>信箱:</span>
                            </el-col>
                            <el-col :span="4" class="selectInpud">
                                <el-input v-model="mail" placeholder="請輸入信箱"></el-input>
                            </el-col>
                        </el-row>
                        <el-row>
                            <el-col :span="4" class="selectTitleMid">
                                <span>會員資料</span>
                            </el-col>
                        </el-row>
                        <el-row>
                            <el-col :span="3" class="selectTitle">
                                <span>姓:</span>
                            </el-col>
                            <el-col :span="4" class="selectInpud">
                                <el-input v-model="name" placeholder="請輸入姓"></el-input>
                            </el-col>
                            <el-col :span="3" class="selectTitle">
                                <span>名:</span>
                            </el-col>
                            <el-col :span="4" class="selectInpud">
                                <el-input v-model="lastName" placeholder="請輸入名"></el-input>
                            </el-col>
                            <el-col :span="3" class="selectTitle">
                                <span>暱稱<span
                                        class="red">*</span>:</span>
                            </el-col>
                            <el-col :span="4" class="selectInpud">
                                <el-input v-model="nickName" placeholder="請輸入暱稱"></el-input>
                            </el-col>
                        </el-row>
                        <el-row>
                            <el-col :span="3" class="selectTitle">
                                <span>性別:</span>
                            </el-col>
                            <el-col :span="4" class="selectInpud">
                                <el-radio-group v-model="gender">
                                    <el-radio label="1">男</el-radio>
                                    <el-radio label="0">女</el-radio>
                                </el-radio-group>
                            </el-col>
                        </el-row>
                    </div>
                </el-col>
            </el-row>
            <div slot="footer" class="popBatton">
                <el-button @click="editSave">儲存</el-button>
                <el-button @click="closeDialog">關閉</el-button>
            </div>
        </el-dialog>
    </div>
</template>

<script>
    // import tableColumns from '@/assets/js/customerAssetsHomeTable.js'
    // 导入Moment.js
    import moment from 'moment';

    export default {
        name: "editDialog",
        components: {},
        props: {
            editDialog: Boolean,
        },
        data() {
            return {
                dialogFull: false,
                row: null,
                token: null,
                //表頭設定
                columns: [],
                //table數據
                // tableData: [],
                staffId: null,
                userName: null,
                password: null,
                errorCount: 0,
                isActive: null,
                companyId: null,
                branchId: null,
                selectCompanyId: null,
                selectBranchId: null,
                role: null,
                state: null,
                unlockDate: null,
                unlockDateString: null,
                mail: null,
                userId: null,
                name: null,
                lastName: null,
                nickName: null,
                gender: null,
                //全部公司下拉選單資料
                companyOptions: [],
                //全部分店下拉選單資料
                branchOptions: [],
                customerId: null,
                passwordVisible: false,
                //角色下拉選單資料
                roleOptions: [],
                loginRole: null,
                reload: false,
                //是否需要公司ID
                isCompanyid: true,
                //是否需要分店ID
                isBranchid: true,
                isSystem: false,
            }
        },
        computed: {
            inputType() {
                return this.passwordVisible ? 'text' : 'password';
            }
        },
        mounted() {
        },
        methods: {
            init(row) {
                console.log("開啟編輯頁面!");
                this.selectCompanyId=null;
                this.selectBranchId=null;
                this.row = row.user;
                if (row != null && row != "") {
                    this.staffId = row.staffId;
                    this.userName = row.userName;
                    this.password = row.password;
                    this.errorCount = row.errorCount;
                    this.isActive = row.isActive == true ? "1" : "0";
                    this.companyId = row.companyId;
                    this.branchId = row.branchId;
                    this.role = row.role;
                    this.state = row.state == 0 ? "0" : "1";
                    this.unlockDate = row.unlockDate;
                    this.mail = row.mail;
                }
                if (this.row != null && this.row != "") {
                    this.userId = this.row.userId;
                    this.name = this.row.name;
                    this.lastName = this.row.lastName;
                    this.nickName = this.row.nickName;
                    this.gender = this.row.gender == null ? null : this.row.gender == true ? "1" : "0";
                }
                this.isCompanyid = JSON.parse(localStorage.getItem('isCompanyid'));
                this.isBranchid = JSON.parse(localStorage.getItem('isBranchid'));
                console.log("editDialog取得isCompanyid=" + this.isCompanyid);
                console.log("editDialog取得isBranchid=" + this.isBranchid);
                const loginReturnDto = localStorage.getItem('loginReturnDto');
                if (loginReturnDto != null && JSON.parse(loginReturnDto) != null) {
                    this.loginReturnDto = JSON.parse(loginReturnDto);
                    this.token = this.loginReturnDto.token;
                    if (this.isCompanyid) {
                        this.selectCompanyId = this.loginReturnDto.companyId;
                        this.selectBranchId = this.loginReturnDto.branchId;
                        console.log("editDialog取得loginReturnDto.companyId=" + this.companyId);
                        console.log("editDialog取得loginReturnDto.branchId=" + this.branchId);
                    }else{
                        //包含系統角色
                        this.isSystem=true;
                    }
                    this.loginRole = this.loginReturnDto.role;
                    console.log("editCompanyDialog取得loginRole=" + this.loginRole);
                    //console.log("editCompanyDialog取得token=" + this.token);
                }
                this.searchCompanyList();
                this.searchBranchList();
                this.searchRoleOptions();
            },
            async editSave() {
                console.log("editSave this.unlockDateString=" + this.unlockDateString);
                console.log("editSave this.unlockDate=" + this.unlockDate);
                //編輯儲存
                let tmp = this;
                let url = "staff/updated/staff";
                try {
                    var user = {
                        "staffId": this.staffId,
                        "userId": this.userId,
                        "name": this.name,
                        "lastName": this.lastName,
                        "nickName": this.nickName,
                        "gender": this.gender == null ? null : parseInt(this.gender, 10),
                    };
                    let parameter = {
                        "staffId": this.staffId,
                        "userName": this.userName,
                        "password": this.password,
                        "errorCount": this.errorCount == null ? null : parseInt(this.errorCount, 10),
                        "isActive": this.isActive == null ? null : this.isActive == "0" ? false : true,
                        "companyId": this.companyId,
                        "branchId": this.branchId,
                        "role": this.role == null ? null : parseInt(this.role, 10),
                        "state": this.state == null ? null : parseInt(this.state, 10),
                        "unlockDate": null,
                        "unlockDateString": this.unlockDateString == null || this.unlockDateString == '' ? this.unlockDate : this.unlockDateString,
                        "mail": this.mail,
                        "user": user,
                    };
                    if (!this.checkEditParameter(parameter)) {
                        console.log("更新驗證資料失敗!");
                        return;
                    }
                    tmp.reload = true;
                    tmp.loadingView();
                    let responseData = await tmp.$api.doPostByMsgToken(parameter, url, this.token);
                    if (responseData) {
                        // tmp.$api.openMsg("編輯資料完成!", "success");
                        console.log("編輯儲存-編輯資料完成!");
                        // 在延迟 2000 毫秒（2 秒）后执行代码
                        setTimeout(function () {
                            tmp.closeDialog();
                            //  tmp.$emit('init');
                        }, 1000);
                    } else {
                        // tmp.$api.openMsg("編輯資料失敗!", "error");
                        console.log("編輯儲存-編輯資料失敗!");
                    }
                } catch (error) {
                    console.log("編輯資料發生異常! error=" + error);
                } finally {
                    tmp.closeLoading();
                }
                tmp.closeLoading();
            },
            loadingView() {
                //開啟載入特效
                this.loadingStatus = this.$loading({
                    lock: true,
                    text: 'Loading',
                    spinner: 'el-icon-loading',
                    background: 'rgba(0, 0, 0, 0.7)'
                });
            },
            closeDialog() {
                console.log("關閉Pop");
                this.$emit('update:reload', this.reload);
                this.$emit('closeDialog');
            },
            closeLoading() {
                //關閉載入特效
                if (this.loadingStatus != null) {
                    this.loadingStatus.close();
                }
            },
            async searchCompanyList() {
                //查詢全部公司下拉選單資料
                let tmp = this;
                let url = "login/search/searchCompanyOptions";
                let parameter = {
                    "companyId": this.selectCompanyId,
                    "companyName": "",
                    "isActive": true
                };
                //取得列表資料
                let responseData = await tmp.$api.doPost(parameter, url);
                if (responseData != null) {
                    tmp.companyOptions = responseData;
                    console.log("取得下拉選單資料 companyOptions=" + tmp.companyOptions);
                }
            },
            async searchBranchList() {
                //查詢全部分店下拉選單資料
                let tmp = this;
                let url = "login/search/searchBranchOptions";
                let parameter = {
                    "companyId": this.selectCompanyId,
                    "branchId": this.selectBranchId,
                    "companyName": "",
                    "isActive": true
                };
                //取得列表資料
                let responseData = await tmp.$api.doPostByToken(parameter, url, this.token);
                if (responseData != null) {
                    tmp.branchOptions = responseData;
                    console.log("取得下拉選單資料 companyOptions=" + tmp.companyOptions);
                }
            },
            async searchRoleOptions(){
                //查詢角色下拉選單資料
                let tmp = this;
                let url = "rolePower/search/rolePower";
                let parameter = {
                    "roleName": null,
                    "role": null,
                    "isSystem": tmp.isSystem,
                };
                //取得列表資料
                let responseData = await tmp.$api.doPostByToken(parameter, url, this.token);
                if (responseData != null) {
                    tmp.roleOptions = responseData;
                    console.log("取得角色下拉選單資料 roleOptions=" + tmp.roleOptions);
                }
            },
            validatePassword() {
                //const input = event.target.value;
                // 只允許英文大小寫字母和數字
                this.password = this.password.replace(/[^a-zA-Z0-9]/g, '');
            },
            validateUsername() {
                // 使用正則表達式檢查用戶名是否僅包含英數字大小寫以及 "-" 和 "_"
                this.userName = this.userName.replace(/[^a-zA-Z0-9-_]/g, '');
            },
            validateErrorCount() {
                // 使用正則表達式檢查用戶名是否僅包含英數字大小寫以及 "-" 和 "_"
                this.errorCount = this.errorCount.replace(/[^\d]/g, '');
            },
            togglePasswordVisibility() {
                //檢視密碼
                this.passwordVisible = !this.passwordVisible;
            },
            checkEditParameter(parameter) {
                var ststus = true;
                if (parameter != null && parameter != '') {
                    if (parameter.userName == null || !parameter.userName) {
                        console.log("帳號不可為空!");
                        this.$api.openMsg("帳號不可為空!", "error");
                        return false;
                    }
                    if (parameter.password == null || !parameter.password) {
                        console.log("密碼不可為空!");
                        this.$api.openMsg("密碼不可為空!", "error");
                        return false;
                    }
                    if (this.isCompanyid) {
                        console.log("編輯驗證 role=" + this.role);
                        if (parameter.companyId == null || !parameter.companyId) {
                            console.log("所屬公司不可為空!");
                            this.$api.openMsg("所屬公司不可為空!", "error");
                            return false;
                        }
                        if (parameter.branchId == null || !parameter.branchId) {
                            console.log("所屬分店不可為空!");
                            this.$api.openMsg("所屬分店不可為空!", "error");
                            return false;
                        }
                    }
                    console.log("角色=" + parameter.role);
                    if (parameter.role == null) {
                        console.log("角色不可為空!");
                        this.$api.openMsg("角色不可為空!", "error");
                        return false;
                    }
                    var user = parameter.user;
                    if (user.nickName == null || !user.nickName) {
                        console.log("暱稱不可為空!");
                        this.$api.openMsg("暱稱不可為空!", "error");
                        return false;
                    }
                } else {
                    console.log("必填資料不可為空!");
                    this.$api.openMsg("必填資料不可為空!", "error");
                    return false;
                }
                return ststus;
            },
            dateChangeFormat(dateStr) {
                console.log("dateChangeFormat dateStr=" + dateStr);
                if (dateStr != null && dateStr != '') {
                    var outputFormat = "YYYY-MM-DD HH:mm:ss";
                    // 创建一个新的 Date 对象
                    var date = new Date(dateStr.toString());
                    // 检查日期是否有效
                    if (isNaN(date.getTime())) {
                        return null;
                    }
                    // 使用 moment.js 库来转换日期格式
                    var formattedDate = moment(date).format(outputFormat);
                    console.log("formattedDate=" + formattedDate);
                    this.unlockDateString = formattedDate;
                } else {
                    this.unlockDateString = null;
                }
            },
            handleCompanyChange(value) {
                console.log("Selected company ID:", value);
                //系統管理員權限時會查詢一次該公司所屬的分店
                this.branchId = null;
                if (!this.isCompanyid) {
                    this.selectCompanyId = value;
                    this.selectBranchId = null;
                    this.searchBranchList();
                }
            }
        }
    }
</script>

<style scoped>
    img {
        width: 50px;
        height: 50px;
        /*height: auto;*/
        margin: 10px 0px 0px 0px;
    }

    /**日期選擇器**/
    .selectInpud .el-date-editor {
        width: 100%;
    }
</style>
